import React, { useEffect, useState } from "react";
import { FormContainer } from "../../layouts/Styled/buttons/FormContainer";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { Add, Edit } from "@material-ui/icons";
import styled from "styled-components";
import { HeaderStyle2, PopUpstyle } from "../../layouts/Styled/HeaderStyle";
import { SubmitButton, SubButton } from "../../layouts/Styled/buttons/submitButton";
import {
  CreateProductMethod,
  GetSingleDesignRequestSlice,
  UpdateProductMethod,
  designRequestReset
} from "../../redux/DesignRequestSlice/DesignRequestSlice";
import { toast } from "react-toastify";
import { GetAllStatesMethod, GetSingleDesignRequestMethod } from "../../Components/HelperMethods/APISMethodsHelper";
import {
  HandleSuccessNotifications,
  HendleErrorsNotifications
} from "../../Components/HelperMethods/HandleNotifications";
import { useParams, useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import Stack from '@mui/material/Stack';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MapIcon from '@mui/icons-material/Map';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import image from '../../layouts/images/vector-maps.jpg';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingAnimation from '../../Components/LoadingAnimation';
import UpdateEstimatedTime from "./PoPUp/UpdateEstimatedTime";
import UpdateStatus from "./PoPUp/UpdateStatus";
import AttachmentsTap from "./Taps/AttachmentsTap";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SingleDesignRequest() {

  const { de_request_id } = useParams();

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);


  const {
    design_request,
    message,
    isSuccess,
    isError,
    isLoading,
    errors,
    responseCode,
  } = useSelector((state) => state.design_request);

  const design_request_val = design_request?.data || [];

  console.log(design_request_val)

  useEffect(() => {
    GetSingleDesignRequestMethod(dispatch, de_request_id)
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [updateStatus, setUpdateStatus] = useState(false);
  const handleOpenUpdateStatus = () => setUpdateStatus(true);
  const handleCloseUpdateStatus = () => setUpdateStatus(false);

  const [updateEstimatedTime, setUpdateEstimatedTime] = useState(false);
  const handleOpenUpdateEstimatedTime = () => setUpdateEstimatedTime(true);
  const handleCloseUpdateEstimatedTime = () => setUpdateEstimatedTime(false);

  console.log(design_request_val)
  return (
    <CreateContainer>
      {isLoading ? (
        <>
          <LoadingAnimation />
        </>
      ) : (
        <Box>
          <Grid container xs={12} sm={12} md={8} style={{ margin: 'auto', display: 'grid' }} spacing={2}>
            <Grid item xs={12}>
              <Grid container spcing={2}>
                <Grid item xs={12} md={8}>
                  <ul className="status_list">
                    <li className={design_request_val?.status === 'pending' ? 'pending' : ''}> Pending </li>
                    <li className={design_request_val?.status === 'declined' ? 'declined' : ''}> Rejected </li>
                    <li className={design_request_val?.status === 'approved' ? 'approved' : ''}>
                      Approved
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SubmitButton onClick={handleOpenUpdateStatus}>
                    <Edit style={{ padding: 0 }} />
                    Update Status
                  </SubmitButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Avatar sx={{ bgcolor: deepPurple[500], width: "75px", height: "75px" }}>
                  {design_request_val?.customer_name?.slice(0, 2)?.toUpperCase()}
                </Avatar>
                <Grid item>
                  <Typography variant="h5">{design_request_val?.customer_name}</Typography>
                  <Typography variant="p" className="text-sub">
                    <span>
                      <MapIcon style={{ paddingTop: "5px" }} />
                      {design_request_val?.customer_address}
                    </span>
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} sx={{ width: '100%', overflow: 'hidden' }}>
              <TableStyle>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Address</TableCell>
                        <TableCell align="center">State</TableCell>
                        <TableCell align="center">County</TableCell>
                        <TableCell align="center">PM:Mike Sins</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="center">{design_request_val?.location}</TableCell>
                        <TableCell align="center">{design_request_val?.state || '___'}</TableCell>
                        <TableCell align="center">{design_request_val?.country || '___'}</TableCell>
                        <TableCell align="center">
                          <Typography style={{ color: "#888" }}>
                            <PhoneAndroidIcon />
                            <MapIcon />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableStyle>
              <img style={{ width: "100%", margin: '10px 0 0 0' }} src={image} alt="Login Animation" />
            </Grid>
            <Grid item sm={12} md={12} sx={{ width: '100%', overflow: 'hidden' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Work Order" {...a11yProps(0)} />
                  <Tab label="Activities" {...a11yProps(1)} />
                  <Tab label="Attachments" {...a11yProps(2)} />
                  <Tab label="Time Frame" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel style={{ background: "#eee" }} value={value} index={0}>
                <Grid item md={12}>
                  <TableStyle>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Number of Panels</TableCell>
                            <TableCell align="center">MPU</TableCell>
                            <TableCell align="center">Roof Type</TableCell>
                            <TableCell align="center"> Roof Mount </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center"> 24 Panels </TableCell>
                            <TableCell align="center"> 100 A </TableCell>
                            <TableCell align="center"> Shingle </TableCell>
                            <TableCell align="center"> Shingle </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableStyle>
                </Grid>
              </TabPanel>
              <TabPanel style={{ background: "#eee" }} value={value} index={1}>
                Activities
              </TabPanel>
              <TabPanel style={{ background: "#eee" }} value={value} index={2}>
                <AttachmentsTap design_request_val={design_request_val} />
              </TabPanel>
              <TabPanel style={{ background: "#eee" }} value={value} index={3}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Typography p={2} vairant="h6" className="step_title">
                      Time Frame
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <SubmitButton onClick={() => handleOpenUpdateEstimatedTime()} p={1}>
                      <Edit />
                      Update Estimated Time
                    </SubmitButton>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <TableStyle>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Estimated Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {design_request_val?.items?.map((e, index) => (
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center"> {e?.title} </TableCell>
                              <TableCell align="center"> {e?.estimated_time} </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableStyle>
                </Grid>
                {design_request_val?.items?.length == 0 && (
                  <>
                    <Grid item sm={12}>
                      <Typography vairant="h6" align="center" style={{ color: 'red' }}>
                        Time Frame Is Empty
                      </Typography>
                    </Grid>
                  </>
                )}
              </TabPanel>
            </Grid>

            {/* <Grid item sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
              <SubButton startIcon={<Edit />} type="submit">
                Decline
              </SubButton>
            </Grid>
            <Grid item sm={12} md={8}>
              <SubmitButton startIcon={<Edit />} type="submit">
                Accept (Expected Earning  $700)
              </SubmitButton>
            </Grid>
          </Grid>
        </Grid> */}

            {/* <Modal
            open={updatePlanSets}
            onClose={handleCloseUpdatePlanSets}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={PopUpstyle}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6">
                    Plan Sets
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    type="file"
                    id="input-with-icon-textfield"
                    name="plan_sets"
                    label="Plan Sets"
                    InputLabelProps={{ shrink: true }}
                  // onChange={(e) => handleUploadImage(e)}
                  />
                </Grid>
                <Grid item md={12}>
                  <SubmitButton onClick={handleCloseUpdatePlanSets}>
                    Send
                  </SubmitButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={updateSiteSurvey}
            onClose={handleCloseUpdateSiteSurvey}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={PopUpstyle}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6">
                    Site Survey
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    type="file"
                    id="input-with-icon-textfield"
                    name="site_survey"
                    label="Site Survey"
                    InputLabelProps={{ shrink: true }}
                  // onChange={(e) => handleUploadImage(e)}
                  />
                </Grid>
                <Grid item md={12}>
                  <SubmitButton onClick={handleCloseUpdateSiteSurvey}>
                    Send
                  </SubmitButton>
                </Grid>
              </Grid>
            </Box>
          </Modal> */}
            <Modal
              open={updateEstimatedTime}
              onClose={handleCloseUpdateEstimatedTime}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <UpdateEstimatedTime handleCloseUpdateEstimatedTime={handleCloseUpdateEstimatedTime} />
            </Modal>
            <Modal
              open={updateStatus}
              onClose={handleCloseUpdateStatus}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <UpdateStatus de_req_status={design_request_val?.status} reason={design_request_val?.design_reject_reson ? design_request_val?.design_reject_reson[0]?.reason : ''} handleCloseUpdateStatus={handleCloseUpdateStatus} />
            </Modal>
          </Grid>
        </Box>
      )}
    </CreateContainer>
  );
}
const CreateContainer = styled.div`
  margin: auto;

  .status_list {
    list-style-type: none;
    padding: 0px;
  }
  .status_list li {
    display: inline-block;
    margin-right: 20px;
    color: #888;
  }
  .status_list li.approved {
    color: #1e7d59;
    font-weight: bold;
    background: #cdffea;
    padding: 5px 10px;
    border-radius: 10px;
  }

  .status_list li.pending {
    color: #F39C12;
    font-weight: bold;
    background: #ffdf5d9e;
    padding: 5px 10px;
    border-radius: 10px;
  }

  .status_list li.declined {
    color: #C0392B;
    font-weight: bold;
    background: #dab7b4a3;
    padding: 5px 10px;
    border-radius: 10px;
  }

  .step_title {
    font-weight: bold;
    color: #888;
    padding: 15px;
  }
`;

const TableStyle = styled.div`
	th {
		font-size: 1.1em;
		padding: 15px;
    color: var(--sub-color)
	}
`;

export default SingleDesignRequest;
