import styled from 'styled-components';

export const SubmitButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 100%;
    background: #01545A;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
	cursor: pointer;
	color: #fff;
	border: 0;
`;

export const SubButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 100%;
	background: #E7EAEE;
    border-radius: 8px;
	cursor: pointer;
	color: #64748B;
	border: 0;
`;

export const FormButtonStyle = styled.button`
	width: 100%;
	padding: 10px;
	font-size: 1.3em;
	background: var(--sub-color);
	color: var(--light);
	border-radius: 10px;
	border: none;
	cursor: pointer;
`;
