import { Edit, Update, VisibilityRounded } from '@material-ui/icons';
import {
	AttachEmail,
	DriveFileRenameOutline,
	Roofing,
	SolarPower,
} from '@mui/icons-material';
import { Avatar, Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, ToggleButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import LoadingAnimation from '../../Components/LoadingAnimation';
import { SubButton, SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import { FormContainer } from '../../layouts/Styled/buttons/FormContainer';
import { HeaderStyle2, ImagePopUpstyle } from '../../layouts/Styled/HeaderStyle';
import CheckIcon from '@mui/icons-material/Check';
import { ShowUserInformationMethod, UpdateUserInformationMethod, authReset } from '../../redux/AuthSlice/AuthSlice';
import { HandleSuccessNotifications, HendleErrorsNotifications } from '../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';

function ShowProfile() {
	const { user, mainUser, isLoading, isError, errors, message, isSuccess, responseCode } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const info = user?.data || [];
	const [data, setData] = useState({});
	const [fetch, setFetch] = useState(false);
	const [form, setForm] = useState(false);

	const handleUpdatedata = (e) => {
		let { name, value } = e.target;
		if (name === 'status')
			setData({ ...data, reason: '' })
		setData({ ...data, [name]: value });
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		setForm(true);
		const formData = new FormData();
		formData.append('user_id', data?.user_id)
		formData.append('name', data?.name)
		formData.append('email', data?.email)
		formData.append('is_pe_s', data?.is_pe_s ? 1 : 0)
		formData.append('is_stamp_holder', data?.is_stamp_holder ? 1 : 0)
		formData.append('is_structural_calculations', data?.is_structural_calculations ? 1 : 0)

		if (data?.logo)
			formData.append('logo', data?.logo)

		if (data?.is_pe_s) {
			if (data?.pe_s_license)
				formData.append('pe_s_license', data?.pe_s_license)
			if (data?.plan_set_per === 'per_wat')
				formData.append('price_sets_per_wat', data?.price_sets_per_wat)
			else
				formData.append('price_sets_per_wat', 0)
			if (data?.plan_set_per === 'per_set')
				formData.append('price_sets_per_set', data?.price_sets_per_set)
			else
				formData.append('price_sets_per_set', 0)
		}

		if (data?.is_stamp_holder && data?.stamp_holder_license)
			formData.append('stamp_holder_license', data?.stamp_holder_license)

		if (data?.is_structural_calculations) {
			if (data?.structural_calculations)
				formData.append('structural_calculations', data?.structural_calculations)
			if (data?.structural_calculations_per === 'per_wat')
				formData.append('price_calculations_per_wat', data?.price_calculations_per_wat)
			// else
			// 	formData.append('price_calculations_per_wat', null)
			if (data?.structural_calculations_per === 'per_calculation')
				formData.append('price_calculations_per_calculation', data?.price_calculations_per_calculation)
			// else
			// 	formData.append('price_calculations_per_calculation', null)
		}
		await dispatch(UpdateUserInformationMethod({ data: formData, user_id: info?.id }))
		await ShowUserMethod()
		await dispatch(authReset());
		// await setFetch(true)
	}


	const handleUploadImage = (e) => {
		let { name } = e.target
		let value = e.currentTarget.files[0];
		setData({ ...data, [name]: value });
	};

	const SetFormData = () => {
		console.log(info)
		if (info) {
			setData({
				user_id: info?.id,
				name: info?.name,
				email: info?.email,
				is_pe_s: parseInt(info?.is_pe_s) == 1 ? true : false,
				price_sets_per_wat: info?.price_sets_per_wat || '',
				price_sets_per_set: info?.price_sets_per_set || '',
				plan_set_per: info?.price_sets_per_set ? 'per_set' : 'per_wat',
				price_calculations_per_wat: info?.price_calculations_per_wat || '',
				price_calculations_per_calculation: info?.price_calculations_per_calculation || '',
				structural_calculations_per: info?.price_calculations_per_calculation ? 'per_calculation' : 'per_wat',
				is_stamp_holder: parseInt(info?.is_stamp_holder) == 1 ? true : false,
				is_structural_calculations: parseInt(info?.is_structural_calculations) == 1 ? true : false,

				// Current Files
				current_logo: info?.logo || '',
				current_stamp_holder_license: info?.stamp_holder_license || '',
				current_pe_s_license: info?.pe_s_license || '',
				current_structural_calculations: info?.structural_calculations || '',
			})
		}
	}
	const ShowUserMethod = async () => {
		await dispatch(ShowUserInformationMethod())
		await dispatch(authReset())
		await setFetch(true)
		// await SetFormData()
	}
	useEffect(() => {
		ShowUserMethod()
	}, [dispatch])

	useEffect(() => {
		setFetch(false)
		SetFormData()
	}, [fetch])

	const CallSuccessMethod = async () => {
		HandleSuccessNotifications(toast, 'Data Updated Successfully')
	}

	useEffect(() => {
		if (isError && form) {
			setForm(false);
			HendleErrorsNotifications(toast, errors, message);
		}

		if (form && isSuccess) {
			setForm(false);
			CallSuccessMethod()
		}
	}, [form, isSuccess, dispatch, isError, isLoading, message, errors, responseCode])

	const [current_image, setCurrentImage] = useState(null)

	const handleShowImage = (e, path) => {
		e.preventDefault();
		setCurrentImage(path);
		handleOpenImage()
	}

	const [openImage, setOpenImage] = useState(false);
	const handleOpenImage = () => setOpenImage(true);
	const handleCloseImage = () => setOpenImage(false);


	console.log(data)

	return (
		<ShowContainer>
			{isLoading ? (
				<>
					<LoadingAnimation />
				</>
			) : (
				<>
					<Grid className="show" container spacing={3} columns={12} mt={3}>
						<Grid item lg={3} md={4} sm={12} sx={{ padidng: '0' }}>
							{console.log(data?.current_logo)}
							<Avatar
								src={data?.current_logo}
								sx={{
									width: '20vh',
									height: '20vh',
									display: 'flex',
									margin: 'auto',
								}}
							/>
							<Grid container spacing={2} mt={3}>
								<Grid item md={12}>
									<hr />
								</Grid>
								<Grid item xs={12}>
									<Typography variant="p" style={{ width: '100%' }} fullWidth>
										<DriveFileRenameOutline pt={1} ></DriveFileRenameOutline>
										<Typography className="user-name" variant="span" ml={2}>
											{info?.name}
										</Typography>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="p" align="center">
										<AttachEmail pt={1} ></AttachEmail>
										<Typography className="user-name" variant="span" ml={2}>
											{info?.email}
										</Typography>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={9} md={8} sm={12}>
							<FormContainer onSubmit={handleSubmit}>
								<HeaderStyle2>
									<Edit /> Edit
								</HeaderStyle2>
								<Grid container spacing={2}>
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											id="name"
											name="name"
											label="Name"
											value={data?.name || null}
											onChange={(e) => handleUpdatedata(e)}
										/>
									</Grid>
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											id="email"
											name="email"
											label="E-mail"
											value={data?.email || null}
											onChange={(e) => handleUpdatedata(e)}
										/>
									</Grid>
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											type="file"
											id="logo"
											name="logo"
											label="Avatar"
											onChange={(e) => handleUploadImage(e)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item sm={12}>
										<Grid container spacing={2}>
											<Grid item md={6} sm={6} xs={12} mb={3}>
												<FormGroup>
													<FormControlLabel
														control={<Checkbox
															id="is_pe_s"
															name="is_pe_s"
															checked={data?.is_pe_s === true ? 'checked' : ''}
															onChange={() => {
																setData({ ...data, is_pe_s: !data?.is_pe_s });
															}}
														/>} label="Are you doing plan Sets ?" />
												</FormGroup>
											</Grid>
											{data?.is_pe_s && (
												<Grid item mb={3} md={6} sm={6} xs={12}>
													<FormControl sx={{ m: 1, width: '100%' }}>
														<InputLabel id="plan_set_per"> Plan Set Price Per </InputLabel>
														<Select
															fullWidth
															labelId="plan_set_per"
															label="plan_set_per"
															id="plan_set_per"
															name="plan_set_per"
															value={data?.plan_set_per || ""}
															onChange={(e) => handleUpdatedata(e)}
															input={<OutlinedInput label="States" />}
														>
															<MenuItem
																value="per_wat"
															>
																Per Watt
															</MenuItem>
															<MenuItem
																value="per_set"
															>
																Per Set
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											)}
											{data?.is_pe_s && data?.plan_set_per === 'per_wat' && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<TextField
														fullWidth
														id="price_sets_per_wat"
														name="price_sets_per_wat"
														value={data?.price_sets_per_wat || null}
														type='number'
														step="0.1"
														label="plan set price per watt"
														onChange={(e) => handleUpdatedata(e)}
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>
											)}
											{data?.is_pe_s && data?.plan_set_per === 'per_set' && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<TextField
														fullWidth
														id="price_sets_per_set"
														name="price_sets_per_set"
														label="plan set price per set"
														onChange={(e) => handleUpdatedata(e)}
														value={data?.price_sets_per_set || null}
														type='number'
														step="0.1"
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>
											)}
											{data?.is_pe_s && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<ButtonGroup aria-label="outlined button group">
														<TextField
															fullWidth
															multiple
															type="file"
															id="pe_s_license"
															name="pe_s_license"
															label="plan set license"
															onChange={(e) => handleUploadImage(e)}
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{data?.current_pe_s_license && (
															<Button style={{ border: 0, padding: 0 }}>
																<SubButton style={{ height: '100%' }} onClick={(e) => handleShowImage(e, data?.current_pe_s_license)}>
																	<VisibilityRounded />
																</SubButton>
															</Button>
														)}
													</ButtonGroup>
												</Grid>
											)}
										</Grid>
									</Grid>

									<Grid item sm={12}>
										<Grid container spacing={2}>
											<Grid item md={6} sm={6} xs={12} mb={3}>
												<FormGroup>
													<FormControlLabel
														control={<Checkbox
															id="is_stamp_holder"
															name="is_stamp_holder"
															checked={data?.is_stamp_holder === true ? 'checked' : ''}
															onChange={() => {
																setData({ ...data, is_stamp_holder: !data?.is_stamp_holder });
															}}
														/>} label="Stamp Holder" />
												</FormGroup>
											</Grid>
											{data?.is_stamp_holder && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<ButtonGroup aria-label="outlined button group">
														<TextField
															fullWidth
															multiple
															type="file"
															id="stamp_holder_license"
															name="stamp_holder_license"
															label="Stamp Holder License"
															onChange={(e) => handleUploadImage(e)}
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{data?.current_stamp_holder_license && (
															<Button style={{ border: 0, padding: 0 }}>
																<SubButton style={{ height: '100%' }} onClick={(e) => handleShowImage(e, data?.current_stamp_holder_license)}>
																	<VisibilityRounded />
																</SubButton>
															</Button>
														)}
													</ButtonGroup>
												</Grid>
											)}
										</Grid>
									</Grid>

									<Grid item sm={12}>
										<Grid container spacing={2}>
											<Grid item md={6} sm={6} xs={12} mb={3}>
												<FormGroup>
													<FormControlLabel
														control={<Checkbox
															id="is_structural_calculations"
															name="is_structural_calculations"
															checked={data?.is_structural_calculations === true ? 'checked' : ''}
															onChange={() => {
																setData({ ...data, is_structural_calculations: !data?.is_structural_calculations });
															}}
														/>} label="Are you doing Structure Calculation ?" />
												</FormGroup>
											</Grid>

											{data?.is_structural_calculations && (
												<Grid item mb={3} md={6} sm={6} xs={12}>
													<FormControl sx={{ m: 1, width: '100%' }}>
														<InputLabel id="structural_calculations_per"> Structural Calculations Price Per </InputLabel>
														<Select
															fullWidth
															labelId="structural_calculations_per"
															id="structural_calculations_per"
															name="structural_calculations_per"
															value={data?.structural_calculations_per || ""}
															onChange={(e) => handleUpdatedata(e)}
															input={<OutlinedInput label="States" />}
														>
															<MenuItem
																value="per_wat"
															>
																Per Watt
															</MenuItem>
															<MenuItem
																value="per_calculation"
															>
																Per Calculation
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											)}
											{data?.is_structural_calculations !== false && data?.structural_calculations_per === 'per_wat' && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<TextField
														fullWidth
														id="price_calculations_per_wat"
														name="price_calculations_per_wat"
														label="Structural Calculations Price Per Watt"
														onChange={(e) => handleUpdatedata(e)}
														value={data?.price_calculations_per_wat || null}
														type='number'
														step="0.1"
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>
											)}
											{data?.is_structural_calculations !== false && data?.structural_calculations_per === 'per_calculation' && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<TextField
														fullWidth
														id="price_calculations_per_calculation"
														name="price_calculations_per_calculation"
														value={data?.price_calculations_per_calculation || null}
														type='number'
														step="0.1"
														label="Structural Calculations Price Per Calculation"
														onChange={(e) => handleUpdatedata(e)}
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>
											)}

											{data?.is_structural_calculations && (
												<Grid item md={6} sm={6} xs={12} mb={3}>
													<ButtonGroup aria-label="outlined button group">
														<TextField
															fullWidth
															multiple
															type="file"
															id="structural_calculations"
															name="structural_calculations"
															label="Structural Calculations"
															onChange={(e) => handleUploadImage(e)}
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{data?.current_structural_calculations && (
															<Button style={{ border: 0, padding: 0 }}>
																<SubButton style={{ height: '100%' }} onClick={(e) => handleShowImage(e, data?.current_structural_calculations)}>
																	<VisibilityRounded />
																</SubButton>
															</Button>
														)}
													</ButtonGroup>
												</Grid>
											)}
										</Grid>
									</Grid>

									<SubmitButton startIcon={<Edit />} type="submit">
										Update
									</SubmitButton>
								</Grid>
							</FormContainer>

							{/* style={{ display: 'flex', justifyContent: 'center' }} */}
							<Modal
								open={openImage}
								onClose={handleCloseImage}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box sx={ImagePopUpstyle}>
									<img src={current_image} className='image_show' style={{ width: '100%', height: '100%' }} />
								</Box>
							</Modal>
						</Grid>
					</Grid>
				</>
			)}
		</ShowContainer>
	);
}
const ShowContainer = styled.div`
	.user-name {
		text-transform: capitalize;
	}
	.edit-button {
		color: var(--text-main);
	}
	.css-iajp3t-MuiButtonGroup-root {
		width: 100%;
	}
`;
export default ShowProfile;
