import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import ForgetPassword from './Pages/Auth/ForgetPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import AllDesignRequest from './Pages/DesignRequest/AllDesignRequest';
import Profile from './Pages/Profile/Profile';
import ShowProfile from './Pages/Profile/ShowProfile';
import CreateProduct from './Pages/DesignRequest/CreateProduct';
import ShowDesignRequest from './Pages/DesignRequest/ShowDesignRequest';
import { Logout } from '@mui/icons-material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EditDesignRequest from './Pages/DesignRequest/EditDesignRequest';
import SingleDesignRequest from './Pages/DesignRequest/SingleDesignRequest';
import DashboardCharts from './Pages/DashboardCharts/DashboardCharts';
// import { browserHistory, Router, IndexRoute } from 'react-router'

function App() {
	// const isAutherized = useSelector((state) => state.auth?.data);
	// const user = JSON.parse(localStorage.getItem('auth'))?.data || null;

	const { mainUser } = useSelector((state) => state.auth)

	// const history = createMemoryHistory(location)

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{mainUser ? (
				<>
					<Routes>
						<Route path="/" element={<Navigate to="/" />} />
						<Route path="*" element={<Navigate to="/" />} />
						<Route path="/" element={<Home />}>
							<Route index element={<DashboardCharts />}></Route>
							<Route path="design-request" element={<AllDesignRequest />}>
								<Route index element={<ShowDesignRequest />}></Route>
								<Route path="create" element={<CreateProduct />}></Route>
								<Route
									path="show/:de_request_id"
									element={<SingleDesignRequest />}
								></Route>
								<Route
									path="edit/:de_request_id"
									element={<EditDesignRequest />}
								></Route>
							</Route>
							<Route path="/profile" element={<Profile />}>
								<Route index element={<ShowProfile />}></Route>
							</Route>
						</Route>
					</Routes>
				</>
			) : (
				<>
					<Routes>
						<Route path="*" element={<Navigate to="/"></Navigate>}></Route>
						<Route path="/" element={<Login />}></Route>
						<Route path="/register" element={<Register />}></Route>
						<Route path="/forget-password" element={<ForgetPassword />}></Route>
					</Routes>
				</>
			)}
		</>
	);
}

export default App;
