import React, { useEffect, useState } from 'react';
import { FormContainer } from '../../layouts/Styled/buttons/FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { Add, Edit } from '@material-ui/icons';
import styled from 'styled-components';
import { HeaderStyle2 } from '../../layouts/Styled/HeaderStyle';
import { SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import {
	GetSingleDesignRequestSlice,
	UpdateDesignRequestMethod,
	designRequestReset,
} from '../../redux/DesignRequestSlice/DesignRequestSlice';
import { toast } from 'react-toastify';
import { GetAllStatesMethod } from '../../Components/HelperMethods/APISMethodsHelper';
import {
	HandleSuccessNotifications,
	HendleErrorsNotifications,
} from '../../Components/HelperMethods/HandleNotifications';
import { useParams, useLocation } from 'react-router-dom';

function EditDesignRequest() {
	const dispatch = useDispatch();

	const location = useLocation();
	console.log(location)
	const { de_request_id } = useParams();
	const {
		product,
		message,
		isSuccess,
		isError,
		isLoading,
		errors,
		responseCode,
	} = useSelector((state) => state.products);
	const productInfo = product?.data;
	const { all_states } = useSelector((state) => state.all_states);

	const AllStates = all_states?.data?.states;

	const [formSubmit, setFormSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			panel_brand: '',
			color: '',
			maximum_rating_power: null,
			panel_wattage: null, // integer
			module_efficiency: null,
			open_circuit_voltage: null,
			maximum_power_voltage: null,
			short_circuit_current: null,
			maximum_power_current: null,
			inverter: null,
			States: [],
			specifications: null,
		},
		onSubmit: async (values) => {
			const form_data = new FormData();
			form_data.append('panel_brand', values.panel_brand);
			form_data.append('color', values.color);
			form_data.append('maximum_rating_power', values.maximum_rating_power);
			form_data.append('panel_wattage', values.panel_wattage);
			form_data.append('module_efficiency', values.module_efficiency);
			form_data.append('open_circuit_voltage', values.open_circuit_voltage);
			form_data.append('maximum_power_voltage', values.maximum_power_voltage);
			form_data.append('short_circuit_current', values.short_circuit_current);
			form_data.append('maximum_power_current', values.maximum_power_current);
			form_data.append('inverter', values.inverter);
			form_data.append('States', values.States);
			form_data.append('specifications', values.specifications);

			setFormSubmit(true);
			await dispatch(UpdateDesignRequestMethod(form_data));
			await dispatch(designRequestReset());
		},
	});

	useEffect(() => {
		GetAllStatesMethod(dispatch);
	}, []);

	useEffect(() => {
		if (isError) {
			HendleErrorsNotifications(toast, errors, message);
		}

		if (isSuccess && formSubmit) {
			setFormSubmit(false);
			HandleSuccessNotifications(toast, 'Design Request Updated Successfuly');
			formik.resetForm();
		}
	}, [
		dispatch,
		isError,
		isSuccess,
		isLoading,
		formSubmit,
		message,
		errors,
		responseCode,
		formik,
	]);

	useEffect(() => {
		const GetSingleProduct = async () => {
			await dispatch(GetSingleDesignRequestSlice(de_request_id));
			await dispatch(designRequestReset());
		};
		GetSingleProduct();
	}, []);

	const handleUploadImage = (e) => {
		formik.values.specifications = e.currentTarget.files[0];
	};

	const [FormikValues, setFormikValues] = useState(true);

	useEffect(() => {
		if (productInfo && FormikValues) {
			setFormikValues(false);
			formik.values.panel_brand = productInfo?.panel_brand;
			formik.values.color = productInfo?.color;
			formik.values.maximum_rating_power = productInfo?.maximum_rating_power;
			formik.values.panel_wattage = productInfo?.panel_wattage;
			formik.values.module_efficiency = productInfo?.module_efficiency;
			formik.values.open_circuit_voltage = productInfo?.open_circuit_voltage;
			formik.values.maximum_power_voltage = productInfo?.maximum_power_voltage;
			formik.values.short_circuit_current = productInfo?.short_circuit_current;
			formik.values.maximum_power_current = productInfo?.maximum_power_current;
			formik.values.inverter = productInfo?.inverter;
			formik.values.States = productInfo?.States;
			console.log(formik.values)
		}
	}, [formik.values, productInfo, FormikValues]);

	return (
		<CreateContainer>
			<FormContainer onSubmit={formik.handleSubmit}>
				<HeaderStyle2>
					<Edit /> Edit{' '}
				</HeaderStyle2>
				<Grid container spacing={2}>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="panel_brand"
							label="Panel Brand"
							value={formik.values.panel_brand || []}
							onChange={formik.handleChange}
							error={
								formik.touched.panel_brand && Boolean(formik.errors.panel_brand)
							}
							helperText={
								formik.touched.panel_brand && formik.errors.panel_brand
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="color"
							label="color"
							value={formik.values.color || []}
							onChange={formik.handleChange}
							error={formik.touched.color && Boolean(formik.errors.color)}
							helperText={formik.touched.color && formik.errors.color}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="maximum_rating_power"
							label="maximum rating power"
							value={formik.values.maximum_rating_power || []}
							onChange={formik.handleChange}
							error={
								formik.touched.maximum_rating_power &&
								Boolean(formik.errors.maximum_rating_power)
							}
							helperText={
								formik.touched.maximum_rating_power &&
								formik.errors.maximum_rating_power
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							type="number"
							id="input-with-icon-textfield"
							name="panel_wattage"
							label="Panel Wattage"
							value={formik.values.panel_wattage || []}
							onChange={formik.handleChange}
							error={
								formik.touched.panel_wattage &&
								Boolean(formik.errors.panel_wattage)
							}
							helperText={
								formik.touched.panel_wattage && formik.errors.panel_wattage
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="module_efficiency"
							label="Module Efficiency"
							value={formik.values.module_efficiency || []}
							onChange={formik.handleChange}
							error={
								formik.touched.module_efficiency &&
								Boolean(formik.errors.module_efficiency)
							}
							helperText={
								formik.touched.module_efficiency &&
								formik.errors.module_efficiency
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="open_circuit_voltage"
							label="maximum rating power"
							value={formik.values.open_circuit_voltage || []}
							onChange={formik.handleChange}
							error={
								formik.touched.open_circuit_voltage &&
								Boolean(formik.errors.open_circuit_voltage)
							}
							helperText={
								formik.touched.open_circuit_voltage &&
								formik.errors.open_circuit_voltage
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="maximum_power_voltage"
							label="Maximum Power Voltage"
							value={formik.values.maximum_power_voltage || []}
							onChange={formik.handleChange}
							error={
								formik.touched.maximum_power_voltage &&
								Boolean(formik.errors.maximum_power_voltage)
							}
							helperText={
								formik.touched.maximum_power_voltage &&
								formik.errors.maximum_power_voltage
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="short_circuit_current"
							label="Short Circuit Current"
							value={formik.values.short_circuit_current || []}
							onChange={formik.handleChange}
							error={
								formik.touched.short_circuit_current &&
								Boolean(formik.errors.short_circuit_current)
							}
							helperText={
								formik.touched.short_circuit_current &&
								formik.errors.short_circuit_current
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="maximum_power_current"
							label="Maximum Power Current"
							value={formik.values.maximum_power_current || []}
							onChange={formik.handleChange}
							error={
								formik.touched.maximum_power_current &&
								Boolean(formik.errors.maximum_power_current)
							}
							helperText={
								formik.touched.maximum_power_current &&
								formik.errors.maximum_power_current
							}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="inverter"
							label="Inverter"
							value={formik.values.inverter || []}
							onChange={formik.handleChange}
							error={formik.touched.inverter && Boolean(formik.errors.inverter)}
							helperText={formik.touched.inverter && formik.errors.inverter}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							fullWidth
							type="file"
							id="input-with-icon-textfield"
							name="specifications"
							label="Specifications"
							onChange={(e) => handleUploadImage(e)}
						/>
					</Grid>

					<Grid item mb={2} md={6} xs={12}>
						<FormControl sx={{ m: 1, width: 300 }}>
							<InputLabel id="demo-multiple-name-label">States</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								multiple
								name="States"
								value={formik.values.States}
								onChange={formik.handleChange}
								input={<OutlinedInput label="States" />}
							// MenuProps={MenuProps}
							>
								{AllStates?.map((state) => (
									<MenuItem
										key={state.id}
										value={state.abbr}
									// style={getStyles(name, personName, theme)}
									>
										{state.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<SubmitButton startIcon={<Edit />} type="submit">
						Update
					</SubmitButton>
				</Grid>
			</FormContainer>
		</CreateContainer>
	);
}
const CreateContainer = styled.div`
	margin: auto;
	width: 50%;
`;

export default EditDesignRequest;
