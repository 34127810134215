import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SubmitButton } from '../../../layouts/Styled/buttons/submitButton'
import { PopUpstyle } from '../../../layouts/Styled/HeaderStyle'
import { UpdateEstimatedTimeMethod, UpdateSiteSurveyMethod } from '../../../redux/DesignRequestSlice/DesignRequestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GetSingleDesignRequestMethod } from '../../../Components/HelperMethods/APISMethodsHelper';
import { useParams } from 'react-router-dom';
import { HandleSuccessNotifications } from '../../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';
import LoadingAnimation from '../../../Components/LoadingAnimation';

function UploadSiteSurvey({ handleCloseUpdateSiteSurvey }) {

    const { de_request_id } = useParams();
    const dispatch = useDispatch();

    const [data, setData] = useState({ designRequest_id: de_request_id });
    const [form, setForm] = useState(false);

    const { isSuccess, isLoading } = useSelector((state) => state.design_request);

    const handleUpdatedata = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const handleSubmit = async () => {
        setForm(true);
        const formData = new FormData();
        formData.append('designRequest_id', data?.designRequest_id)
        formData.append('media[]', data?.media)
        await dispatch(UpdateSiteSurveyMethod(formData))
    }

    const CallSuccessMethod = async () => {
        HandleSuccessNotifications(toast, 'Site Survey Updated Successfully')
        await setForm(false);
        await GetSingleDesignRequestMethod(dispatch, de_request_id);
        await handleCloseUpdateSiteSurvey();
    }

    useEffect(() => {
        if (form && isSuccess) {
            CallSuccessMethod()
        }
    }, [form, isSuccess])

    const handleUploadImage = (e) => {
        let { name } = e.target
        let value = e.currentTarget.files[0];
        setData({ ...data, [name]: value });
    };

    return (
        <div>
            <Box sx={PopUpstyle}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography variant="h6">
                            Site Survey
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            multiple
                            type="file"
                            id="input-with-icon-textfield"
                            name="media"
                            label="media"
                            onChange={(e) => handleUploadImage(e)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {!isLoading && (
                        <Grid item sm={12}>
                            <SubmitButton onClick={(e) => handleSubmit()}>
                                Send
                            </SubmitButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </div>
    )
}

export default UploadSiteSurvey
