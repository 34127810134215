import React from 'react'
import { Chart } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Container, Grid, Typography } from '@mui/material';
import DesignRequestStatusDoughnut from './ChartsLayouts/DesignRequestStatusDoughnut';
import BarChart from './ChartsLayouts/BarChart';
import AreaChart from './ChartsLayouts/AreaChart';
import RadarChart from './ChartsLayouts/RadarChart';
import styled from 'styled-components';
import { Edit } from '@material-ui/icons';



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function DashboardCharts() {
  return (
    <Container >
      <Grid container spacing={2} style={{ marginTop: '30px' }}>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12} xs={12}>
            <CardContainer className='blue_gradent'>
              <span className="icon">
                <Edit />
              </span>
              <Typography variant="h5">
                Pending Requests
              </Typography>
              <Typography variant="h6" className='chart-count'>
                5
              </Typography>
            </CardContainer>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <CardContainer className='purple_gradent'>
              <span className="icon">
                <Edit />
              </span>
              <Typography variant="h5">
                Rejected Requests
              </Typography>
              <Typography variant="h6" className='chart-count'>
                8
              </Typography>
            </CardContainer>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <CardContainer className='orange_gradent'>
              <span className="icon">
                <Edit />
              </span>
              <Typography variant="h5">
                Completed Requests
              </Typography>
              <Typography variant="h6" className='chart-count'>
                31
              </Typography>
            </CardContainer>
          </Grid>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <BarChart />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <DesignRequestStatusDoughnut />
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <AreaChart />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <RadarChart />
        </Grid>
      </Grid>
    </Container>
  )
}
// 20001873428964
export default DashboardCharts

const CardContainer = styled.div`
    width: '100%';
    position: relative;
    color:#fff;
    min-height: 142px;
    padding: 20px 20px 10px 20px;
    line-height: 64px;
    border-radius: 20px;
    span.icon {
      position: absolute;
      right: 10px;
    }
    span.icon svg {
      font-size: 7em;
      opacity: .5;
    }
    .chart-count {
      font-size: 4em
    }
`