import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Mail, VpnKey } from '@material-ui/icons';
import image from '../../layouts/images/login/73208-jurupoles-login.gif';
// import image from '../../layouts/images/login/Rectangle-3299.png';
import styled from 'styled-components';
import { SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import { LoginUserMethod, authReset } from '../../redux/AuthSlice/AuthSlice';
import { toast } from 'react-toastify';

// validate the form in login
const validationSchema = yup.object({
	email: yup
		.string('Enter your email')
		.email('Enter a valid email')
		.required('Email is required'),
	password: yup
		.string('Enter your password')
		.min(8, 'Password should be of minimum 8 characters length')
		.required('Password is required'),
});

// start a login function
const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form, setForm] = useState(false);

	const {
		user,
		isSuccess,
		isError,
		errors,
	} = useSelector((state) => state.auth);

	// initial value for form
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const form_data = new FormData();

			form_data.append('email', values.email);
			form_data.append('password', values.password);

			setForm(true);
			await dispatch(LoginUserMethod(form_data));
			await dispatch(authReset());
		},
	});

	useEffect(() => {
		//if there is an error
		if (isError) {
			errors.map((error) => {
				toast.error(error?.msg);
			});
		}

		//if the login sucsess
		if (isSuccess && form) {
			setForm(false);
			localStorage.setItem('auth', JSON.stringify(user));
			window.location.reload()
		}

		//dispatch reset
		dispatch(authReset());
	}, [isError, errors, isSuccess, dispatch, navigate]);

	const auth = JSON.parse(localStorage.getItem('auth'))?.data || null;

	return (
		<>
			<LoginContainer sx={{ padding: { xs: '5px', sm: '20px 10px', md: '50px 202px' } }}>
				<Grid style={{ height: '100%' }} container spacing={2}>
					<Grid item sx={{
						display: { xs: 'none', sm: 'none', md: 'flex' }, height: '100%',
						overflow: 'hidden',
						justifyContent: 'center'
					}} xs={0} sm={12} md={6}>
						<img src={image} alt="Login Animation" style={{
							margin: 'auto',
							maxWith: '100%',
							maxHeight: '100%'
						}} />
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<Typography variant="h2" className="login-text">
							Sign in
						</Typography>
						<form onSubmit={formik.handleSubmit} mt={3} sx={{ padding: { md: '0 20px' } }}>
							<Grid container>
								<Grid item mb={2} xs={12}>
									<TextField
										fullWidth
										id="input-with-icon-textfield"
										name="email"
										label="Email"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Mail />
												</InputAdornment>
											),
										}}
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</Grid>
								<Grid item mb={2} xs={12}>
									<TextField
										fullWidth
										id="input-with-icon-textfield"
										name="password"
										label="Password"
										type="password"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<VpnKey />
												</InputAdornment>
											),
										}}
										value={formik.values.password}
										onChange={formik.handleChange}
										error={
											formik.touched.password && Boolean(formik.errors.password)
										}
										helperText={
											formik.touched.password && formik.errors.password
										}
									/>
								</Grid>
								<Grid item sm={12} md={12} mb={3}>
									<Link to="/forget-password" className="text-sub"> Forget Password</Link>
								</Grid>
							</Grid>

							<SubmitButton
								fullWidth
								type="submit"
								variant="outlined"
								mt={5}
								p={3} style={{ margin: "0 0 15px 0" }}
							>
								Let's Go
							</SubmitButton>
							<Typography variant="p" align="center" color="info">
								Don’t have an account yet ?
								<Link
									className="sign-up text-sub"
									to="/register"
									mt={3}
									xs={{ textAlign: 'center' }}
								>
									Sing up
								</Link>
							</Typography>
						</form>
					</Grid>
				</Grid>
			</LoginContainer>
		</>
	);
};

const LoginContainer = styled.div`
	height:90vh;
	.login-text {
		margin: 0 0 50px 0;
		font-weight: bold;
		color: var(--main-color)
	}
	
	.sign-up {
		padding: 10px;
	}
`;
export default Login;

