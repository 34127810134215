import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GetAllDesignRequestMethod } from '../../Components/HelperMethods/APISMethodsHelper';
import styled from '@emotion/styled';
import {
	Button, Box, Typography, Modal,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import LoadingAnimation from '../../Components/LoadingAnimation';
import DeletePopUp from './PoPUp/DeletePopUp';
import {
	DeleteProductSlice,
	designRequestReset,
} from '../../redux/DesignRequestSlice/DesignRequestSlice';
import {
	HandleSuccessNotifications,
	HendleErrorsNotifications,
} from '../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';
import { Download } from '@mui/icons-material';
import { Link, NavLink } from 'react-router-dom';
import { SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import Tooltip from '@mui/material/Tooltip';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};


const rows = [createData('Frozen yoghurt', 159, 6.0, 24, 4.0)];

const ShowDesignRequest = () => {
	const dispatch = useDispatch();
	const {
		design_requests,
		isError,
		isSuccess,
		isLoading,
		errors,
		responseCode,
		message,
	} = useSelector((state) => state.design_request);

	const design_requests_val = design_requests?.data || [];


	// Handle Delete PoPUp
	const [openDeletePopUp, setOpenDeletePopup] = useState(false);
	const [data, setData] = useState({});
	const [submitStateus, setSubmitStatus] = useState(false);
	const handelCloseDeletePopup = () => {
		setOpenDeletePopup(false);
	};

	// const handleDeleteSubmit = async () => {
	// 	setOpenDeletePopup(false);
	// 	setSubmitStatus(true);
	// 	await dispatch(DeleteProductSlice(data.id));
	// 	await GetAllDesignRequestMethod(dispatch);
	// };

	useEffect(() => {
		GetAllDesignRequestMethod(dispatch);
	}, []);

	const HandleDeleteDesignRequest = (data) => {
		setOpenDeletePopup(true);
		setData(data);
	};

	useEffect(() => {
		if (isError) {
			HendleErrorsNotifications(toast, errors, message);
		}
		if (isSuccess && submitStateus) {
			setSubmitStatus(false);
			HandleSuccessNotifications(toast, 'Product Deleted Successfuly');
		}
	}, [errors, isError, isSuccess, message, submitStateus]);

	// const [status, setStatus] = useState(true);

	// const handelOpenStatus = () => {
	// 	handleOpenUpdateStatus();
	// 	setStatus(true)
	// }

	const [anchorEl, setAnchorEl] = useState(null);
	const openDropDown = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const closeDropDown = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<TableStyle>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell> # </TableCell>
									<TableCell align="center">name</TableCell>
									<TableCell align="center">address</TableCell>
									<TableCell align="center">plan set</TableCell>
									<TableCell align="center">installation </TableCell>
									<TableCell align="center">stamped </TableCell>
									<TableCell align="center">status</TableCell>
									<TableCell align="center">contractor</TableCell>
									<TableCell align="center">runs </TableCell>
									<TableCell align="center">mailed </TableCell>
									<TableCell align="center">Acions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{design_requests_val?.map((e, index) => (
									<TableRow
										key={index}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{index + 1}
										</TableCell>
										<TableCell align="center">{e?.customer_name}</TableCell>
										<TableCell align="center">{e?.customer_address}</TableCell>
										<TableCell align="center">{e?.plan_set}</TableCell>
										<TableCell align="center">{e?.installation_type}</TableCell>
										<TableCell align="center">{e?.stamped_plan_set}</TableCell>
										<TableCell align="center">
											<span style={{ 'color': e?.status === "pending" ? '#F39C12' : e?.status === "declined" ? '#C0392B' : 'green', fontWeight: '#1e7d59' }}>
												{e?.status}
											</span>
										</TableCell>
										<TableCell align="center">{e?.contractor_name}</TableCell>
										<TableCell align="center">{e?.runs_type}</TableCell>
										<TableCell align="center">{e?.mailed_type}</TableCell>
										<TableCell align="center">
											{/* <Button
												id="basic-button"
												aria-controls={openDropDown ? 'basic-menu-' + e?.id : undefined}
												aria-haspopup="true"
												aria-expanded={openDropDown ? 'true' : undefined}
												onClick={handleClick}
											>
												<ArrowDropDownIcon />
											</Button> */}
											{/* <Menu
												id={'basic-menu-' + e?.id}
												anchorEl={anchorEl}
												open={openDropDown}
												onClose={closeDropDown}
												MenuListProps={{
													'aria-labelledby': 'basic-button',
												}}
											>
												<MenuItem onClick={closeDropDown}>
													<Button onClick={handelOpenStatus} className="text-main" style={{ fontSize: "0.7em" }}>
														Update Status
													</Button>
												</MenuItem>
												<MenuItem onClick={closeDropDown}>
													<Button onClick={handleOpenUpdateSiteSurvey} className="text-main" style={{ fontSize: "0.7em" }}>
														Upload Site Survey
													</Button>
												</MenuItem>
												<MenuItem onClick={closeDropDown}>
													<Button onClick={handleOpenUpdatePlanSets} className="text-main" style={{ fontSize: "0.7em" }}>
														Upload Plan Sets
													</Button>
												</MenuItem>
												<MenuItem onClick={closeDropDown}>
													<Button onClick={handleOpenUpdateEstimatedTime} className="text-main" style={{ fontSize: "0.7em" }}>
														Estimated Time
													</Button>
												</MenuItem>
												<MenuItem onClick={closeDropDown}>
													<NavLink p={0} to={'show/' + e?.id}>
														<Button startIcon={<VisibilityIcon />} px={0} color="success" className="text-main">
															Show
														</Button>
													</NavLink>
												</MenuItem>
												<MenuItem onClick={closeDropDown}>
													<NavLink p={0} to={'edit/' + e?.id}>
														<Button startIcon={<Edit />} px={0} color="success" className="text-main">
															Edit
														</Button>
													</NavLink>
												</MenuItem>
												<MenuItem className="menuwqeqw" onClick={closeDropDown}>
													<Button
														startIcon={<Delete />}
														className="text-main" style={{ fontSize: "0.7em" }}
														onClick={(e) => HandleDeleteDesignRequest(e?.id)}
													>
														Delete
													</Button>
												</MenuItem>
											</Menu> */}

											{/* <MenuItem onClick={closeDropDown}>
												<NavLink p={0} to={'edit/' + e?.id}>
													<Button startIcon={<Edit />} px={0} color="success" className="text-main">
													</Button>
												</NavLink>
											</MenuItem>
											<MenuItem className="menuwqeqw" onClick={closeDropDown}>
												<Button
													startIcon={<Delete />}
													className="text-main" style={{ fontSize: "0.7em" }}
													onClick={(e) => HandleDeleteDesignRequest(e?.id)}
												>
												</Button>
											</MenuItem> */}

											<NavLink p={0} to={'show/' + e?.id}>
												<Button
													className="text-main" style={{ fontSize: "0.7em" }}
													startIcon={<Edit />}
												>
												</Button>
											</NavLink>
											{/* <Button
												onClick={(e) => HandleDeleteDesignRequest(e?.id)}
												className="text-main" style={{ fontSize: "0.7em" }}
												startIcon={<Delete />}
											>
											</Button> */}
											{/* {e??.file?.specifications ? (
												<a
													href={product?.file?.specifications}
													target="_blank"
													alt="specifications"
												>
													<Button
														disabled={
															product?.file?.specifications ? false : true
														}
													>
														<Download />
													</Button>
												</a>
											) : (
												<Button disabled>
													<Download />
												</Button>
											)} */}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<DeletePopUp
						open={openDeletePopUp}
						hendleClose={handelCloseDeletePopup}
						title={data.name}
					// handleDeleteSubmit={handleDeleteSubmit}
					/>
				</TableStyle>
			)}
		</>
	);
};

const TableStyle = styled.div`
	th {
		font-size: 1.1em;
		padding: 15px;
	}
`;

export default ShowDesignRequest;
