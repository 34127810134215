import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DesignRequestService from './DesignRequestService';
import { GetUserToken } from '../../Components/HelperMethods/UserToken';
import { GetErrorMessage, GetErrorsObj, GetResponseCode } from '../../Components/HelperMethods/ReturnErrors';

export const GetAllDesignRequestSlice = createAsyncThunk(
	'design_request/all',
	async (data, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await DesignRequestService.GetAllDesignRequestSlice(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const GetSingleDesignRequestSlice = createAsyncThunk(
	'design_request/single',
	async (designRequest_id, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await DesignRequestService.GetSingleDesignRequestSlice(designRequest_id, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const CreateDesignRequestMethod = createAsyncThunk(
	'design_request/create',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.CreateDesignRequestMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdateDesignRequestMethod = createAsyncThunk(
	'design_request/update',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.UpdateDesignRequestMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdateEstimatedTimeMethod = createAsyncThunk(
	'design_request/update_estimated_time',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.UpdateEstimatedTimeMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdateSiteSurveyMethod = createAsyncThunk(
	'design_request/update_estimated_time',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.UpdateSiteSurveyMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UploadStructuralCalculationsMethod = createAsyncThunk(
	'design_request/update_estimated_time',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.UploadStructuralCalculationsMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdatePlanSetsMethod = createAsyncThunk(
	'design_request/update_estimated_time',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.UpdatePlanSetsMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdateDesignRequestStatusMethod = createAsyncThunk(
	'design_request/update_status',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await DesignRequestService.UpdateDesignRequestStatusMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const DeleteDesignRequestSlice = createAsyncThunk(
	'design_request/delete',
	async (designRequest_id, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await DesignRequestService.DeleteDesignRequestSlice(designRequest_id, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

const DesignRequestSlice = createSlice({
	name: 'design_request',
	initialState: {
		design_request: [],
		design_requests: [],
		isSuccess: false,
		isError: false,
		isLoading: false,
		errors: false,
		responseCode: false,
		message: null,
	},
	reducers: {
		designRequestReset: (state) => {
			state.isSuccess = false;
			state.isError = false;
			state.isLoading = false;
			state.errors = false;
			state.responseCode = false;
			state.message = null;
		},
	},
	extraReducers: {
		[GetAllDesignRequestSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[GetAllDesignRequestSlice.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_requests = action.payload;
		},
		[GetAllDesignRequestSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[GetSingleDesignRequestSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[GetSingleDesignRequestSlice.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[GetSingleDesignRequestSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[CreateDesignRequestMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[CreateDesignRequestMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[CreateDesignRequestMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UpdateDesignRequestMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdateDesignRequestMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[UpdateDesignRequestMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UpdateEstimatedTimeMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdateEstimatedTimeMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[UpdateEstimatedTimeMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UpdateSiteSurveyMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdateSiteSurveyMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[UpdateSiteSurveyMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UploadStructuralCalculationsMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UploadStructuralCalculationsMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[UploadStructuralCalculationsMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UpdatePlanSetsMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdatePlanSetsMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[UpdatePlanSetsMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UpdateDesignRequestStatusMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdateDesignRequestStatusMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.design_request = action.payload;
		},
		[UpdateDesignRequestStatusMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[DeleteDesignRequestSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[DeleteDesignRequestSlice.fulfilled]: (state) => {
			state.isLoading = false;
			state.isSuccess = true;
		},
		[DeleteDesignRequestSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},
	},
});

export const { designRequestReset } = DesignRequestSlice.actions;
export default DesignRequestSlice.reducer;
