import axios from 'axios';
import { DESIGN_REQUEST } from '../../layouts/API';

const GetAllDesignRequestSlice = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${DESIGN_REQUEST}`,
		headers: headers,
		props: { ...data },
	});
	return response.data.data;
};

const GetSingleDesignRequestSlice = async (design_id, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${DESIGN_REQUEST}/single/${design_id}`,
		headers: headers,
	});
	return response.data;
};

const CreateDesignRequestMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/store`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UpdateDesignRequestMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/update`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UpdateEstimatedTimeMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/store-item`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UpdateSiteSurveyMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/store-site-survey`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UploadStructuralCalculationsMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/store-structural-calculations`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UpdatePlanSetsMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/store-plan-sets`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UpdateDesignRequestStatusMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${DESIGN_REQUEST}/update-status`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const DeleteDesignRequestSlice = async (design_id, token) => {
	let headers = {
		Accept: 'aplication/json',
		Authorization: `Bearer ${token}`,
	};

	let response = await axios({
		method: 'DELETE',
		baseURL: `${DESIGN_REQUEST}/delete/${design_id}`,
		headers: headers,
	});
	return response.data;
};

const DesignRequestService = {
	GetAllDesignRequestSlice,
	GetSingleDesignRequestSlice,
	CreateDesignRequestMethod,
	UpdateDesignRequestMethod,
	DeleteDesignRequestSlice,
	UpdateEstimatedTimeMethod,
	UpdateDesignRequestStatusMethod,
	UpdateSiteSurveyMethod,
	UpdatePlanSetsMethod,
	UploadStructuralCalculationsMethod,
};

export default DesignRequestService;
