import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SubButton, SubmitButton } from '../../../layouts/Styled/buttons/submitButton'
import { PopUpstyle } from '../../../layouts/Styled/HeaderStyle'
import { UpdateDesignRequestStatusMethod } from '../../../redux/DesignRequestSlice/DesignRequestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GetSingleDesignRequestMethod } from '../../../Components/HelperMethods/APISMethodsHelper';
import { useParams } from 'react-router-dom';
import { HandleSuccessNotifications, HendleErrorsNotifications } from '../../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';
import LoadingAnimation from '../../../Components/LoadingAnimation';
import { ConstructionOutlined } from '@mui/icons-material';

const status = ['pending', 'approved', 'declined'];

function UpdateStatus({ handleCloseUpdateStatus, de_req_status, reason }) {

    console.log(reason)
    const { de_request_id } = useParams();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        id: de_request_id,
        status: de_req_status,
        reason: reason,
        _method: 'PUT'
    });
    const [form, setForm] = useState(false);

    const {
        isSuccess,
        isLoading,
        message,
        errors,
        isError,
        responseCode
    } = useSelector((state) => state.design_request);

    const handleUpdatedata = (e) => {
        let { name, value } = e.target;
        if (name === 'status')
            setData({ ...data, reason: '' })
        setData({ ...data, [name]: value });
    }
    const handleSubmit = async () => {
        await setForm(true);
        await dispatch(UpdateDesignRequestStatusMethod(data))
    }

    const CallSuccessMethod = async () => {
        await GetSingleDesignRequestMethod(dispatch, de_request_id);
        await handleCloseUpdateStatus();
    }

    useEffect(() => {
        if (isError) {
            HendleErrorsNotifications(toast, errors, message);
        }

        if (form && isSuccess) {
            HandleSuccessNotifications(toast, 'Status Updated Successfully')
            setForm(false);
            CallSuccessMethod()
        }
    }, [
        form,
        isSuccess,
        dispatch,
        isError,
        isSuccess,
        isLoading,
        message,
        errors,
        responseCode
    ])

    // console.log(data?.status === 'declined' && data?.reason?.length < 1 ? true : false)

    return (
        <div>
            <Box sx={PopUpstyle}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Update Status
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-name-label">Status</InputLabel>
                            <Select
                                labelId="demo-name-label"
                                id="demo-name"
                                name="status"
                                value={data?.status || null}
                                onChange={handleUpdatedata}
                                input={<OutlinedInput label="Status" />}
                            >
                                {status?.map((state, index) => (
                                    <MenuItem
                                        key={index}
                                        value={state}
                                    >
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {data?.status === 'declined' && (
                        <Grid item xs={12}>
                            <TextField required fullWidth onChange={(e) => handleUpdatedata(e)} name="reason" value={data?.reason || ''} id="filled-basic" label="The Reason" variant="filled" />
                        </Grid>
                    )}
                    {/* {data?.status === 'declined' && data?.reason?.length < 1 ? (
                        <></>
                    ) : ( */}
                    <Grid item xs={12}>
                        <SubmitButton className={data?.status === 'declined' && data?.reason?.length < 1 ? 'disabled' : ''} onClick={() => handleSubmit()}>
                            Update
                        </SubmitButton>
                    </Grid>
                    {/* )} */}

                </Grid>
            </Box>
        </div >
    )
}

export default UpdateStatus
