import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice/AuthSlice';
import DesignRequestSlice from './DesignRequestSlice/DesignRequestSlice';
import StateSlice from './StateSlice/StateSlice';

export const store = configureStore({
	reducer: {
		auth: AuthSlice,
		design_request: DesignRequestSlice,
		all_states: StateSlice,
	},
});
