import { Box, Button, Grid, Modal, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import styled from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SubmitButton } from '../../../layouts/Styled/buttons/submitButton';
import { PopUpstyle } from '../../../layouts/Styled/HeaderStyle';
import UploadPlanSets from '../PoPUp/UploadPlanSets';
import UploadSiteSurvey from '../PoPUp/UploadSiteSurvey';
import UploadStructuralCalculations from '../PoPUp/UploadStructuralCalculations';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function AttachmentsTap({ design_request_val }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [updateSiteSurvey, setUpdateSiteSurvey] = useState(false);
    const handleOpenUpdateSiteSurvey = () => setUpdateSiteSurvey(true);
    const handleCloseUpdateSiteSurvey = () => setUpdateSiteSurvey(false);

    const [updatePlanSets, setUpdatePlanSets] = useState(false);
    const handleOpenUpdatePlanSets = () => setUpdatePlanSets(true);
    const handleCloseUpdatePlanSets = () => setUpdatePlanSets(false);

    const [updateStructuralCalculations, setUpdateStructuralCalculations] = useState(false);
    const handleOpenUpdateStructuralCalculations = () => setUpdateStructuralCalculations(true);
    const handleCloseUpdateStructuralCalculations = () => setUpdateStructuralCalculations(false);

    return (
        <div>
            <Grid item sm={12} md={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Plan Sets" {...a11yProps(0)} />
                        <Tab label="Site Survey" {...a11yProps(1)} />
                        <Tab label="Structural Calculations" {...a11yProps(1)} />
                        {/* <Tab label="Time Frame" {...a11yProps(3)} /> */}
                    </Tabs>
                </Box>
                <TabPanel style={{ background: "#eee" }} value={value} index={0}>
                    <Grid container mb={2}>
                        <Grid sm={8}>
                        </Grid>
                        <Grid sm={4}>
                            <SubmitButton onClick={handleOpenUpdatePlanSets}>
                                Upload Plan Sets
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    <Grid sm={12}>
                        <TableStyle>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Title</TableCell>
                                            <TableCell align="center">Download</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {design_request_val['plan-sets']?.map((row) => (
                                            <>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center"> Plan Sets </TableCell>
                                                    <TableCell align="center">
                                                        <Button>
                                                            <a href={row?.path} target="_blank">
                                                                <VisibilityIcon />
                                                            </a>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {design_request_val['plan-sets']?.length == 0 && (
                                <Typography vairant="h6" align="center" style={{ padding: '10px', color: 'red' }}>
                                    no files to display
                                </Typography>
                            )}
                        </TableStyle>
                    </Grid>
                </TabPanel>
                <TabPanel style={{ background: "#eee" }} value={value} index={1}>
                    <Grid container mb={2}>
                        <Grid sm={8}>
                        </Grid>
                        {/* <Grid sm={4}>
                            <SubmitButton onClick={handleOpenUpdateSiteSurvey}>
                                Upload Site Survey
                            </SubmitButton>
                        </Grid> */}
                    </Grid>

                    <Grid item sm={12}>
                        <TableStyle>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Title</TableCell>
                                            <TableCell align="center">Download</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {design_request_val['site-survey']?.map((row) => (
                                            <>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center"> Site Survey </TableCell>
                                                    <TableCell align="center">
                                                        <Button>
                                                            <a href={row?.path} target="_blank">
                                                                <VisibilityIcon />
                                                            </a>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {design_request_val['site-survey']?.length == 0 && (
                                <Typography vairant="h4" align="center" style={{ padding: '10px', color: 'red' }}>
                                    no files to display
                                </Typography>
                            )}
                        </TableStyle>
                    </Grid>
                </TabPanel>
                <TabPanel style={{ background: "#eee" }} value={value} index={2}>
                    <Grid container mb={2}>
                        <Grid sm={8}>
                        </Grid>
                        <Grid sm={4}>
                            <SubmitButton onClick={handleOpenUpdateStructuralCalculations}>
                                Upload Structural Calculations
                            </SubmitButton>
                        </Grid>
                    </Grid>

                    <Grid item sm={12}>
                        <TableStyle>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Title</TableCell>
                                            <TableCell align="center">Download</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {design_request_val['structural-calculations']?.map((row) => (
                                            <>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">Structural Calculations</TableCell>
                                                    <TableCell align="center">
                                                        <Button>
                                                            <a href={row?.path} target="_blank">
                                                                <VisibilityIcon />
                                                            </a>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {design_request_val['structural-calculations']?.length == 0 && (
                                <Typography vairant="h6" align="center" style={{ padding: '10px', color: 'red' }}>
                                    no files to display
                                </Typography>
                            )}
                        </TableStyle>
                    </Grid>
                </TabPanel>
            </Grid>
            <Modal
                open={updatePlanSets}
                onClose={handleCloseUpdatePlanSets}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <UploadPlanSets handleCloseUpdatePlanSets={handleCloseUpdatePlanSets} />
            </Modal>
            <Modal
                open={updateSiteSurvey}
                onClose={handleCloseUpdateSiteSurvey}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <UploadSiteSurvey handleCloseUpdateSiteSurvey={handleCloseUpdateSiteSurvey} />
            </Modal>
            <Modal
                open={updateStructuralCalculations}
                onClose={handleCloseUpdateStructuralCalculations}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <UploadStructuralCalculations handleCloseUpdateStructuralCalculations={handleCloseUpdateStructuralCalculations} />
            </Modal>
        </div>
    )
}

const TableStyle = styled.div`
	th {
		font-size: 1.1em;
		padding: 15px;
    color: var(--sub-color)
	}
`;

export default AttachmentsTap
