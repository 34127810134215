import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SubmitButton } from '../../../layouts/Styled/buttons/submitButton'
import { PopUpstyle } from '../../../layouts/Styled/HeaderStyle'
import { UpdateEstimatedTimeMethod } from '../../../redux/DesignRequestSlice/DesignRequestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GetSingleDesignRequestMethod } from '../../../Components/HelperMethods/APISMethodsHelper';
import { useParams } from 'react-router-dom';
import { HandleSuccessNotifications } from '../../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';
import LoadingAnimation from '../../../Components/LoadingAnimation';

function UpdateEstimatedTime({ handleCloseUpdateEstimatedTime }) {

    const { de_request_id } = useParams();
    const dispatch = useDispatch();

    const [data, setData] = useState({ designRequest_id: de_request_id });
    const [form, setForm] = useState(false);

    const { isSuccess, isLoading } = useSelector((state) => state.design_request);

    const handleUpdatedata = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const handleSubmit = async () => {
        setForm(true);
        await dispatch(UpdateEstimatedTimeMethod(data))
    }

    const CallSuccessMethod = async () => {
        HandleSuccessNotifications(toast, 'Estimated Time Updated Successfully')
        await setForm(false);
        await GetSingleDesignRequestMethod(dispatch, de_request_id);
        await handleCloseUpdateEstimatedTime();
    }

    useEffect(() => {
        if (form && isSuccess) {
            CallSuccessMethod()
        }
    }, [form, isSuccess])

    return (
        <div>
            <Box sx={PopUpstyle}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography variant="h6">
                            Estimated Time
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            type="text"
                            id="input-with-icon-textfield"
                            name="title"
                            label="Title"
                            value={data?.title}
                            // onChange={(e) => setData({ e.target.name : e.target.value})}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => handleUpdatedata(e)}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            type="text"
                            id="input-with-icon-textfield"
                            name="estimated_time"
                            label="EstimatedTime"
                            value={data?.estimated_time}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => handleUpdatedata(e)}
                        />
                    </Grid>

                    {!isLoading && (
                        <Grid item sm={12}>
                            <SubmitButton onClick={(e) => handleSubmit()}>
                                Send
                            </SubmitButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </div>
    )
}

export default UpdateEstimatedTime
