import {
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Mail, VerifiedUser, VpnKey } from '@material-ui/icons';
import image from '../../layouts/images/login/73208-jurupoles-login.gif';
import styled from 'styled-components';
import { SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import * as yup from 'yup';
import { RegisterUserMethod, authReset } from '../../redux/AuthSlice/AuthSlice';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

// validate the form in login
const validationSchema = yup.object({
	email: yup
		.string('Enter your email')
		.email('Enter a valid email')
		.required('Email is required'),
});

// start a login function
const ForgetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user, isError, isSuccess, isLoading, errors, resopnseCode } =
		useSelector((state) => state.auth);
	// initial value for form
	const formik = useFormik({
		initialValues: {
			name: '',
			photo: '',
			email: '',
			password: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const form_data = new FormData();

			form_data.append('name', values.name);
			form_data.append('email', values.email);
			form_data.append('photo', values.photo);
			form_data.append('password', values.password);

			await dispatch(RegisterUserMethod(form_data));
			await dispatch(authReset());
		},
	});

	useEffect(() => {
		if (isError) {
			errors.map((error) => {
				toast.error(error?.msg);
			});
		}

		if (isSuccess || user) {
			navigate('/');
		}
		dispatch(authReset());
	}, [isError, errors, isSuccess, resopnseCode, dispatch]);

	const handelUploadPhoto = (e) => {
		console.log(e)
	}

	// the data return
	return (
		<>
			<LoginContainer
				sx={{ padding: { xs: "5px", sm: "20px 10px", md: "50px 202px" } }}
			>
				<Grid style={{ height: "100%" }} container spacing={2}>
					<Grid
						item
						sx={{
							display: { xs: "none", sm: "none", md: "flex" },
							height: "100%",
							overflow: "hidden",
							justifyContent: "center"
						}}
						xs={0}
						sm={12}
						md={6}
					>
						<img
							src={image}
							alt="Login Animation"
							style={{
								margin: "auto",
								maxWith: "100%",
								maxHeight: "100%"
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<Typography variant="h2" className="login-text">
							Forget Password
						</Typography>
						<form onSubmit={formik.handleSubmit} mt={3} autocomplete="off">
							<Grid container>
								<Grid item mb={2} xs={12}>
									<TextField
										fullWidth
										id="input-with-icon-textfield"
										name="email"
										label="Email"
										type="email" autocomplete="false"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Mail />
												</InputAdornment>
											),
										}}
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
									/>
								</Grid>

								<SubmitButton fullWidth variant="outlined"
									p={3} style={{ margin: "0 0 15px 0" }}
								>
									Send
								</SubmitButton>

								<Typography variant="p" align="center" color="info">
									Back To Login :
									<Link
										className="sign-up text-sub"
										to="/login"
										mt={3}
										xs={{ textAlign: 'center' }}
									>
										{" "} Sign in
									</Link>
								</Typography>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</LoginContainer>
		</>
	);
};

const LoginContainer = styled.div`
	.login-text {
		margin: 0 0 50px 0;
		font-weight: bold;
		color: var(--main-color)
	}
`;
export default ForgetPassword;
