import { FormatAlignCenter } from '@material-ui/icons';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StateService from './StateService';
import {
	GetErrorMessage,
	GetErrorsObj,
	GetResponseCode,
} from '../../Components/HelperMethods/ReturnErrors';
import { GetUserToken } from '../../Components/HelperMethods/UserToken';

export const AllStatesMethod = createAsyncThunk(
	'states/all',
	async (_, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await StateService.AllStatesMethod(token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

const StateSlice = createSlice({
	name: 'states',
	initialState: {
		all_states: [],
		isLoading: false,
		isError: false,
		isSuccess: false,
		errors: [],
		message: null,
		responseCode: null,
	},
	reducers: {
		stateReset: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = false;
			state.errors = [];
			state.message = null;
			state.responseCode = null;
		},
	},
	extraReducers: {
		[AllStatesMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[AllStatesMethod.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.all_states = payload;
		},
		[AllStatesMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isLoading = false;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},
	},
});

export const { stateReset } = StateSlice.actions;

export default StateSlice.reducer;
