import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Mail, VerifiedUser, VpnKey } from "@material-ui/icons";
import image from "../../layouts/images/login/73208-jurupoles-login.gif";
import styled from "styled-components";
import { SubmitButton } from "../../layouts/Styled/buttons/submitButton";
import * as yup from "yup";
import { RegisterUserMethod, authReset } from "../../redux/AuthSlice/AuthSlice";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

// validate the form in login
const validationSchema = yup.object({
  name: yup.string("Enter Your Name").required("Your Name Is Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
});

// start a login function
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState(false);

  const { user, isError, isSuccess, isLoading, errors, resopnseCode } =
    useSelector((state) => state.auth);
  // initial value for form
  const formik = useFormik({
    initialValues: {
      name: "",
      logo: null,
      email: "",
      password: "",
      is_pe_s: 0,
      pe_s_license: null,
      is_stamp_holder: 0,
      stamp_holder_license: null,
      is_structural_calculations: 0,
      structural_calculations: null
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const form_data = new FormData();

      form_data.append("name", values.name);
      form_data.append("email", values.email);
      //   form_data.append("logo", values.logo);
      form_data.append("password", values.password);
      form_data.append("is_pe_s", values.is_pe_s);
      form_data.append("is_stamp_holder", values.is_stamp_holder);
      form_data.append(
        "is_structural_calculations",
        values.is_structural_calculations
      );
      setForm(true);
      await dispatch(RegisterUserMethod(form_data));
      await dispatch(authReset());
    }
  });

  useEffect(() => {
    if (isError) {
      errors.map((error) => {
        toast.error(error?.msg);
      });
    }
    console.log(user);
    if (isSuccess && form) {
      setForm(false);
      // navigate("/");
      window.location.reload();
    }
    dispatch(authReset());
  }, [isError, errors, isSuccess, resopnseCode, dispatch]);

  const handelUploadPhoto = (e) => {
    console.log(e);
  };

  // the data return
  return (
    <LoginContainer
      sx={{ padding: { xs: "5px", sm: "20px 10px", md: "50px 202px" } }}
    >
      <Grid style={{ height: "100%" }} container spacing={2}>
        <Grid
          item
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            height: "100%",
            overflow: "hidden",
            justifyContent: "center"
          }}
          xs={0}
          sm={12}
          md={6}
        >
          <img
            src={image}
            alt="Login Animation"
            style={{
              margin: "auto",
              maxWith: "100%",
              maxHeight: "100%"
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h2" className="login-text">
            Register
          </Typography>
          <form onSubmit={formik.handleSubmit} mt={3} autocomplete="off">
            <Grid container>
              <Grid item mb={2} xs={12}>
                <TextField
                  fullWidth
                  id="input-with-icon-textfield"
                  name="name"
                  label="Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <AbcIcon /> */}
                      </InputAdornment>
                    )
                  }}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />
              </Grid>
              <Grid item mb={2} xs={12}>
                <TextField
                  fullWidth
                  id="input-with-icon-textfield"
                  name="email"
                  label="Email"
                  type="email"
                  autocomplete="false"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    )
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
              </Grid>
              {/* <Grid item mb={2} xs={12}>
                  <TextField
                    fullWidth
                    id="input-with-icon-textfield"
                    name="logo"
                    label="LOGO"
                    type="file"
                    onChange={(e) => handelUploadPhoto(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AddAPhotoIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid> */}

              <Grid item mb={2} xs={12}>
                <TextField
                  fullWidth
                  id="input-with-icon-textfield"
                  name="password"
                  type="password"
                  label="Password"
                  autocomplete="false"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VerifiedUser />
                      </InputAdornment>
                    )
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                />
              </Grid>
            </Grid>

            <SubmitButton
              fullWidth
              variant="outlined"
              p={3}
              style={{ margin: "0 0 15px 0" }}
            >
              Register
            </SubmitButton>

            <Typography variant="p" align="center" color="info">
              You Already Have An Account ?
              <Link
                className="sign-up text-sub"
                to="/login"
                mt={3}
                xs={{ textAlign: "center" }}
              >
                {" "}
                Sing in
              </Link>
            </Typography>
          </form>
        </Grid>
      </Grid>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  .login-text {
    margin: 0 0 50px 0;
    font-weight: bold;
    color: var(--main-color)
  }
`;
export default Register;
