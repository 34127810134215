import {
	GetAllDesignRequestSlice,
	GetSingleDesignRequestSlice,
	designRequestReset,
} from '../../redux/DesignRequestSlice/DesignRequestSlice';
import { AllStatesMethod, stateReset } from '../../redux/StateSlice/StateSlice';

export const GetAllStatesMethod = async (dispatch) => {
	await dispatch(AllStatesMethod());
	await dispatch(stateReset());
};

export const GetAllDesignRequestMethod = async (dispatch, params) => {
	await dispatch(GetAllDesignRequestSlice(params));
	await dispatch(designRequestReset());
};

export const GetSingleDesignRequestMethod = async (dispatch, de_request_id, params) => {
	await dispatch(GetSingleDesignRequestSlice(de_request_id));
	await dispatch(designRequestReset());
};
